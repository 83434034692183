import XRegExp from "xregexp";
import find from "lodash/find";
import { reactive } from "vue";
import { Song, SongPart, SongPartLine, SongPartLineTypes } from "@/types/song";

export const useSongParts = (song: Song | undefined) => {
    const parts: Array<SongPart> = reactive([]);
    const input = song ? song.input : "";

    const getSongPartMatch = (part: SongPart): SongPart | undefined => {
        const match: any | undefined = find(
            parts,
            (x) =>
                ((x.name && part.name && x.name.replace(/\s*/g, "") == part.name.replace(/\s*/g, ""))
                    || (x.bracketName && part.bracketName && x.bracketName.replace(/\s*/g, "") == part.bracketName.replace(/\s*/g, "")))
                && x.lines.length
        );
        return match;
    }

    const getSongParts = (): void => {
        const REPEAT_PATTERN = `(?:\\s*repeat=(?<repeat>\\d)\\s*)|(?:(?<repeat2>\\d+)x)`;
        const LINES_PATTERN = `(?:<ch>(?<chord>.*?)</ch>)|(?:<p>(?<text>.*?)</p>)`;
        const NAME_PATTERN = `(<part-name>(?<name>.*?)</part-name>)?`;
        const NAME_BRACKET_PATTERN = `\\[(?<bracket>.*?)\\]`;
        const PART_PATTERN = `(?<part><part>${NAME_PATTERN}(?<content>.*?)</part>)`;

        XRegExp.forEach(input, XRegExp(PART_PATTERN, "g"), (part, i) => {
            const repeatResult = part.name
                ? XRegExp.exec(part.name, XRegExp(REPEAT_PATTERN))
                : null;
            const repeat = repeatResult
                ? repeatResult.repeat || repeatResult.repeat2
                : 1;

            const bracketNameResult = part.name ? XRegExp.exec(part.name, XRegExp(NAME_BRACKET_PATTERN)) : null;
            const bracketName = (bracketNameResult && bracketNameResult.bracket) || "";

            const lines: Array<SongPartLine> = [];

            XRegExp.forEach(
                part.content,
                XRegExp(LINES_PATTERN, "g"),
                (line, j) => {
                    const songPartLine: SongPartLine = {
                        type: line.chord
                            ? SongPartLineTypes.CHORD
                            : SongPartLineTypes.TEXT,
                        content: line.chord || line.text
                    };
                    lines.push(songPartLine);
                }
            );

            const songPart: SongPart = {
                name: part.name,
                bracketName: bracketName,
                repeat: isNaN(repeat) ? 1 : Number(repeat),
                repeated: 1,
                lines: lines,
                inViewport: false
            };

            // Only push part if it has any lines AND a name
            // Or there is another part already wth the same name
            if ((part.name && lines.length) || getSongPartMatch(songPart)) {
                parts.push(songPart);
            }
        });
    }

    const fillEmptyParts = (): void => {
        // For each part check if it doesn't have any lines, if so, check if there is a part with the same name and add those lines
        parts.forEach((part) => {
            if (!part.lines.length) {
                const match = getSongPartMatch(part);
                if (match) {
                    part.lines = part.lines.concat(
                        JSON.parse(JSON.stringify(match.lines))
                    );
                }
            }
        });
    };

    getSongParts();
    fillEmptyParts();

    return { parts };
}