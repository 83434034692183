<template>
  <section
    v-if="settings.chords"
    class="transposer"
    :class="{ offset: settings.songNavigation }"
  >
    <div v-if="settings.capo" class="transposer-group">
      <small>Capo</small>
      <div class="transposer-buttons">
        <button @click="capoAdd(-1)" class="button-secondary">-</button>
        <span>{{ capo }}</span>
        <button @click="capoAdd(1)" class="button-secondary">+</button>
      </div>
    </div>
    <div v-if="settings.transpose" class="transposer-group">
      <small>Key</small>
      <div class="transposer-buttons">
        <button @click="keyAdd(-1)" class="button-secondary">-</button>
        <span
          >{{ key }}<sup v-if="keyDiff">{{ keyDiff }}</sup></span
        >
        <button @click="keyAdd(1)" class="button-secondary">+</button>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { Song } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useTranspose } from "@/hooks/use-transpose";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { settings } = useAppState();
    const {
      getTranspose,
      saveTransposes,
      getKeyDiff,
      getNewKey,
      getNewCapo,
    } = useTranspose();
    const transpose = computed(() => getTranspose(song));

    const key = computed(() => transpose.value.key);
    const capo = computed(() => transpose.value.capo);

    const keyAdd = (value: number): void => {
      transpose.value.key = getNewKey(transpose.value.key, value);
      saveTransposes();
    };

    const capoAdd = (value: number): void => {
      transpose.value.capo = getNewCapo(transpose.value.capo, value);
      saveTransposes();
    };

    const keyDiff = computed(() => {
      const diff = getKeyDiff(transpose.value.key, song.key);
      return diff == 0 ? "" : diff > 0 ? "+" + diff : diff;
    });

    return {
      settings,
      key,
      keyDiff,
      keyAdd,
      capo,
      capoAdd,
    };
  },
});
</script>

<style lang="scss">
$transposer-height: 123px;

.transposer {
  position: sticky;
  bottom: $grid-padding;
  width: min-content;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 10;
  height: 0;

  @include min-md() {
    bottom: 2rem;
  }

  @include max-sm() {
    margin-right: -3px;

    @media (orientation: landscape) {
      flex-direction: row;
    }
  }

  &.offset {
    bottom: #{$grid-padding + 40px};

    @include min-md() {
      bottom: calc(2rem + 60px);
    }
  }

  &-group {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 1rem;

      @include max-sm() {
        @media (orientation: landscape) {
          margin-top: 0;
          margin-left: 1rem;
        }
      }
    }

    small {
      backdrop-filter: blur(10px);
      margin: 0 auto;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    backdrop-filter: blur(10px);

    button,
    span {
      padding: 0;
      height: 35px;
      width: 35px;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 1px solid var(--white-500);
      border-top: none;
      border-bottom: none;

      sup {
        @include font-weight-bold();
        position: absolute;
        top: 7px;
        right: 2px;
        font-size: 70%;
      }
    }
  }
}
</style>