
import throttle from "lodash/throttle";
import { defineComponent, onMounted, onUnmounted, PropType, ref } from "vue";
import { Song, SongPart } from "@/types/song";
import { useAuth } from "@/hooks/use-auth";
import { useAppState } from "@/hooks/use-app-state";
import { useUtils, ScrollOptions } from "@/hooks/use-utils";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { loggedIn } = useAuth();
    const { settings } = useAppState();
    const { smoothScroll } = useUtils();
    const navigation = ref(null);
    const blockScroll = ref(false);

    const scrollTo = (part: SongPart, index: number) => {
      const element = document.querySelector(
        `.song-part:nth-child(${index + 1})`
      ) as HTMLElement;
      smoothScroll(element, { offset: loggedIn ? -75 : -135 } as ScrollOptions);

      element.classList.add("is--navigated");
      setTimeout(() => element.classList.remove("is--navigated"), 2000);

      const button: HTMLElement | null = document.querySelector(
        `.song-navigation-button:nth-child(${index + 1})`
      );
      if (button && navigation.value) {
        blockScroll.value = true;

        smoothScroll(
          button,
          {
            direction: "horizontal",
            offset: -(
              // @ts-ignore: Object is possibly 'null'
              (navigation.value.offsetWidth / 2 - button.offsetWidth / 2)
            ),
          } as ScrollOptions,
          navigation.value
        );

        setTimeout(() => {
          blockScroll.value = false;
          song.parts.forEach((x) => (x.inViewport = false));
          part.inViewport = true;
        }, 1000);
      }
    };

    const scrollToButton = throttle(
      () => {
        const button: HTMLElement | null = document.querySelector(
          `.song-navigation-button.bold`
        );

        if (navigation.value && button && !blockScroll.value) {
          // @ts-ignore: Object is possibly 'null'
          navigation.value.scroll(
            button.offsetLeft -
              // @ts-ignore: Object is possibly 'null'
              (navigation.value.offsetWidth / 2 - button.offsetWidth / 2),
            0
          );
        }
      },
      100,
      { leading: true, trailing: true }
    );

    onMounted(() => window.addEventListener("scroll", scrollToButton));
    onUnmounted(() => window.removeEventListener("scroll", scrollToButton));

    return { navigation, scrollTo, settings };
  },
});
