
import { defineComponent, computed, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useAppState } from "@/hooks/use-app-state";
import { useAuth } from "@/hooks/use-auth";
import { useUser } from "@/hooks/use-user";
import { useSongs } from "@/hooks/use-songs";
import { useSongParts } from "@/hooks/use-song-parts";
import Topbar from "@/components/Topbar.vue";
import TopbarItem from "@/components/TopbarItem.vue";
import SongContent from "@/components/song/SongContent.vue";
import SongSuggestions from "@/components/song/SongSuggestions.vue";
import SongNavigation from "@/components/song/SongNavigation.vue";
import Transposer from "@/components/Transposer.vue";
import Backtrack from "@/components/Backtrack.vue";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { settings, saveSettings } = useAppState();
    const { loggedIn } = useAuth();
    const { user } = useUser();
    const { getSong } = useSongs();
    const song = computed(() => getSong(route.params.id as string));

    const getSongParts = () => {
      if (song.value) {
        const { parts } = useSongParts(song.value);
        song.value.parts = parts;
      }
    };

    getSongParts();

    watch(
      () => song.value,
      () => {
        getSongParts();
      }
    );

    const deleteSong = async () => {
      var confirmed = window.confirm(
        "Weet je zeker dat je dit nummer wilt verwijderen?"
      );
      if (confirmed && song.value) {
        const { deleteSong } = useSongs();
        const title = song.value.title;
        const res = await deleteSong(song.value);
        if (res) {
          toast(`"${title}" is verwijderd.`);
          router.go(-1);
        } else {
          toast("Er is iets misgegaan, probeer later opnieuw.");
        }
      }
    };

    const printSong = () => {
      const responsive = settings.value.responsive;
      settings.value.responsive = true;
      nextTick(() => {
        window.print();
        settings.value.responsive = responsive;
      });
    };

    watch(settings.value, (curr, prev) => {
      saveSettings();
    });

    return {
      song,
      loggedIn,
      isAdmin: computed(() => loggedIn.value && user && user.isAdmin),
      settings,
      deleteSong,
      printSong,
    };
  },
  components: {
    Topbar,
    TopbarItem,
    SongContent,
    SongSuggestions,
    SongNavigation,
    Transposer,
    Backtrack,
  },
});
