
import {
  defineComponent,
  PropType,
  onMounted,
  computed,
  watch,
  nextTick,
  onUnmounted,
} from "vue";
import Transposer from "@/helpers/transpose/Transposer";
import { Song, SongPartLineTypes } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useTranspose } from "@/hooks/use-transpose";
import { useSongAnchors } from "@/hooks/use-song-anchors";
import { useUtils, ScrollOptions } from "@/hooks/use-utils";
import { throttle } from "lodash";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { settings } = useAppState();
    const { lineIsCurrentAnchor, lineIsActive, nextAnchor } = useSongAnchors(
      song.parts
    );
    const { getTranspose } = useTranspose();
    const transpose = computed(() => getTranspose(song));
    const { smoothScroll } = useUtils();

    const chordify = (content: string): string => {
      return content.replace(/(\S+)/g, "<ch>$1</ch>");
    };

    const transposed = (content: string): string => {
      if (song.key) {
        if (settings.value.numberSystem) {
          return Transposer.transpose(content)
            .fromKey(song.key)
            .toNumberSystem();
        } else {
          let result = Transposer.transpose(content).fromKey(song.key);

          if (settings.value.transpose) {
            result = result.toKey(transpose.value.key);
          }

          if (settings.value.capo && transpose.value.capo > 0) {
            result = result.down(transpose.value.capo);
          }

          return result.toString();
        }
      }
      return content;
    };

    const setStickyStyleRight = () => {
      const chords = document.querySelectorAll(".chords");
      chords.forEach((line) => {
        let totalWidth = 0;
        for (let index = line.children.length - 1; index >= 0; index--) {
          const chord = line.children[index] as HTMLElement;
          const nextChord = line.children[index + 1] as HTMLElement;
          const chordChild = chord.children[0] as HTMLElement;

          if (chordChild && chordChild.textContent) {
            const characterWidth = window.innerWidth < 768 ? 9 : 9.61;
            chordChild.style.width =
              chordChild.textContent.length * characterWidth + "px"; // because <sub> has a smaller font, we want it the same width;
          }

          totalWidth += nextChord ? nextChord.offsetWidth + 6 : 0; // 6 for a little gap
          chord.style.right = totalWidth + "px";
        }
      });
    };

    const scrollVertical = throttle((offset: number = 0) => {
      smoothScroll(null, { offset } as ScrollOptions);
    }, 325, {
      leading: true,
      trailing: false,
    });

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode == 34 || event.keyCode == 33) {
        event.preventDefault();
        nextAnchor(event.keyCode);
      }

      if (event.keyCode == 38) {
        event.preventDefault();
        scrollVertical(-window.innerHeight * 0.4);
      }

      if (event.keyCode == 40) {
        event.preventDefault();
        scrollVertical(window.innerHeight * 0.4);
      }
    };

    watch([settings.value, transpose.value], () => {
      nextTick(() => setStickyStyleRight());
    });

    onMounted(() => {
      setStickyStyleRight();
      document.onkeydown = onKeyDown;
    });
    onUnmounted(() => (document.onkeydown = null));

    return {
      settings,
      SongPartLineTypes,
      chordify,
      transposed,
      lineIsCurrentAnchor,
      lineIsActive,
    };
  },
});
