import { computed, reactive } from "vue";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import { COMBINED_SCALE } from "@/helpers/transpose";

interface AudioState {
    crossTime: number,
    audios: Array<any>,
    interval: number | null,
    progress: number
}

interface AudioItem {
    name: string,
    file: any,
    playing: boolean,
    interval: number | null
}

let audioState: AudioState;

export const useAudio = () => {

    const getAudioFiles = (): Array<AudioItem> => {
        const files: Array<AudioItem> = [];
        COMBINED_SCALE.forEach((note: string) => {
            const item = <AudioItem>{
                name: note,
                file: new Audio(require(`@/assets/audio/${note.replace('#', 'sharp')}.mp3`)),
                playing: false,
                interval: null
            };
            item.file.volume = 0;
            item.file.loop = true;
            files.push(item);
        });
        return files;
    }

    const startProgressInterval = (audio: AudioItem) => {
        if (audioState.interval) {
            window.clearInterval(audioState.interval);
        }

        audioState.interval = setInterval(() => {
            audioState.progress = 100 * audio.file.currentTime / audio.file.duration;

            if (audio.file.duration - audio.file.currentTime <= (audioState.crossTime / 1000) + 1) {
                const currAudioPlaying = find(audioState.audios, x => x.playing);
                if (currAudioPlaying) {
                    stopAudio(currAudioPlaying, false, false);
                    setTimeout(() => {
                        if (currAudioPlaying.playing) {
                            playAudio(currAudioPlaying);
                        }
                    }, audioState.crossTime);
                }
            }
        }, 500)
    }

    const stopProgressInterval = () => {
        if (audioState.interval) {
            window.clearInterval(audioState.interval);
            audioState.interval = null;
        }
    }

    audioState = audioState || reactive<AudioState>({
        crossTime: 1000,
        audios: getAudioFiles(),
        interval: null,
        progress: 0
    });

    const playing = computed(() => audioState.audios.filter(x => x.playing).length > 0);

    const stopAudio = (audio: AudioItem, pause: boolean = false, setPlaying: boolean = true,): void => {
        const item: AudioItem | null = find(audioState.audios, audio);
        if (item) {
            if (setPlaying) {
                item.playing = false;
            }

            if (item.interval) {
                window.clearInterval(item.interval);
            }

            item.interval = setInterval(() => {
                if (item.file.volume > 0 && item.file.volume - .01 > 0) {
                    item.file.volume -= .01;
                } else {
                    item.file.volume = 0;
                    item.file.pause();
                    if (!pause) {
                        item.file.currentTime = 0;
                    }

                    if (item.interval) {
                        window.clearInterval(item.interval);
                        item.interval = null;
                    }
                }
            }, audioState.crossTime / 100);
        }

        stopProgressInterval();
    }

    const stopAllAudio = (exclude: AudioItem | null = null): void => {
        audioState.audios.filter(x => x.playing && !isEqual(exclude, x)).forEach(x => stopAudio(x));
    }

    const playAudio = (audio: AudioItem): void => {
        const item: AudioItem | null = find(audioState.audios, audio);
        stopAllAudio(item);

        if (item) {
            item.playing = true;
            item.file.play();

            if (item.interval) {
                window.clearInterval(item.interval);
            }

            item.interval = setInterval(() => {
                if (item.file.volume < 1 && item.file.volume + .01 < 1) {
                    item.file.volume += .01;
                } else {
                    item.file.volume = 1;

                    if (item.interval) {
                        window.clearInterval(item.interval);
                        item.interval = null;
                    }
                }
            }, audioState.crossTime / 100);

            startProgressInterval(item);
        }
    }

    return { audioState, playing, playAudio, stopAudio, stopAllAudio };
}