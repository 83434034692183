
import { defineComponent, PropType, ref } from "vue";
import { useAppState } from "@/hooks/use-app-state";
import { useSongs } from "@/hooks/use-songs";
import { Song } from "@/types/song";
import SongList from "@/components/song/SongList.vue";
import sampleSize from "lodash/sampleSize";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
    amount: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { songs } = useSongs();
    const { settings } = useAppState();

    const getRandomSongs = () => {
      return sampleSize(
        songs.value.filter((x) => x._id != song._id),
        props.amount
      );
    };

    const randomSongs = ref(getRandomSongs());

    const refreshSongs = () => {
      randomSongs.value = getRandomSongs();
    };

    return {
      randomSongs,
      settings,
      refreshSongs,
    };
  },
  components: { SongList },
});
