<template>
  <div v-if="settings.backtrack && audio" class="backtrack">
    <div class="backtrack-button-group">
      <button
        @click="play"
        v-if="!audio.playing"
        title="Play"
        class="backtrack-button"
      >
        <icon icon="play"></icon>
      </button>
      <button
        @click="stop"
        v-if="audio.playing || playing"
        title="Stop"
        class="backtrack-button stop"
      >
        <icon v-if="audio.playing" icon="pause"></icon>
        <icon v-if="!audio.playing && playing" icon="stop"></icon>
        <span class="progress" :style="{ width: `${progress}%` }"></span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import find from "lodash/find";
import { computed, defineComponent, PropType, watch } from "vue";
import { Song } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useTranspose } from "@/hooks/use-transpose";
import { useAudio } from "@/hooks/use-audio";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { audioState, playing, playAudio, stopAudio, stopAllAudio } =
      useAudio();
    const { settings } = useAppState();
    const { getTranspose } = useTranspose();
    const transpose = computed(() => getTranspose(song));
    const progress = computed(() => audioState.progress);
    const audio = computed(() =>
      find(
        audioState.audios,
        (x) =>
          x.name == (settings.value.transpose ? transpose.value.key : song.key)
      )
    );

    const play = () => {
      playAudio(audio.value);
    };

    const stop = () => {
      if (audio.value.playing) {
        stopAllAudio(audio.value);
        stopAudio(audio.value, true);
      } else {
        stopAllAudio();
      }
    };

    watch([transpose.value, () => settings.value.transpose], () => {
      if (playing.value) {
        playAudio(audio.value);
      }
    });

    watch(
      () => settings.value.backtrack,
      () => {
        if (playing.value && !settings.value.backtrack) {
          stopAllAudio();
        }
      }
    );

    return { settings, transpose, playing, audio, play, stop, progress };
  },
});
</script>

<style lang="scss">
.backtrack {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  transform: translateY(70px);
  top: 0px;
  height: 0;
  z-index: 450;

  @include max-md() {
    transform: translateY(0px);
    top: 75px;
  }

  &-button-group {
    display: flex;
    flex-direction: column;

    @include max-sm() {
      @media (orientation: landscape) {
        flex-direction: row;
      }
    }
  }

  &-button {
    @include button-icon-only(primary);
    flex: 0 0 $icon-height;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;

    @include max-sm() {
      @media (orientation: landscape) {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }

    .progress {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0;
      background-color: rgba(var(--black-500-rgb), 0.3);
      transition: width 0.5s linear;
    }
  }
}
</style>