
import { computed, defineComponent, PropType } from "vue";
import { Song } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useTranspose } from "@/hooks/use-transpose";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { settings } = useAppState();
    const {
      getTranspose,
      saveTransposes,
      getKeyDiff,
      getNewKey,
      getNewCapo,
    } = useTranspose();
    const transpose = computed(() => getTranspose(song));

    const key = computed(() => transpose.value.key);
    const capo = computed(() => transpose.value.capo);

    const keyAdd = (value: number): void => {
      transpose.value.key = getNewKey(transpose.value.key, value);
      saveTransposes();
    };

    const capoAdd = (value: number): void => {
      transpose.value.capo = getNewCapo(transpose.value.capo, value);
      saveTransposes();
    };

    const keyDiff = computed(() => {
      const diff = getKeyDiff(transpose.value.key, song.key);
      return diff == 0 ? "" : diff > 0 ? "+" + diff : diff;
    });

    return {
      settings,
      key,
      keyDiff,
      keyAdd,
      capo,
      capoAdd,
    };
  },
});
