<template>
  <section
    class="song-suggestions"
    :class="{ 'crop-width': (settings.capo || settings.transpose) && settings.chords }"
    v-if="randomSongs.length && settings.suggestions"
  >
    <h3 class="song-suggestions-title">
      Suggesties
      <button class="song-suggestions-refresh" @click="refreshSongs()" title="Refresh">
        <icon icon="sync-alt" />
      </button>
    </h3>
    <SongList :songs="randomSongs" :replaceRoute="true" :showLetter="false" />
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { useAppState } from "@/hooks/use-app-state";
import { useSongs } from "@/hooks/use-songs";
import { Song } from "@/types/song";
import SongList from "@/components/song/SongList.vue";
import sampleSize from "lodash/sampleSize";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
    amount: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { songs } = useSongs();
    const { settings } = useAppState();

    const getRandomSongs = () => {
      return sampleSize(
        songs.value.filter((x) => x._id != song._id),
        props.amount
      );
    };

    const randomSongs = ref(getRandomSongs());

    const refreshSongs = () => {
      randomSongs.value = getRandomSongs();
    };

    return {
      randomSongs,
      settings,
      refreshSongs,
    };
  },
  components: { SongList },
});
</script>

<style lang="scss">
.song-suggestions {
  padding: 1rem 0;
  border-top: 1px solid var(--white-500);

  &.crop-width {
    max-width: calc(100% - 35px);
  }

  &-title {
    display: flex;
    align-items: center;
  }

  &-refresh {
    @extend %button-reset;
    @include clickableArea($horizontal: 8px, $vertical: 5px);
    margin-left: 1rem;

    svg {
      font-size: 16px;
    }
  }
}
</style>