
import { defineComponent, ref, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  emits: {
    click: null,
    input: null,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    modelValue: {
      type: Boolean,
      default: undefined,
    },
    tooltip: String,
  },
  setup(props, { emit }) {
    const checkboxValue = ref(props.modelValue || false);
    const checkboxChanged = () => {
      emit("input", checkboxValue.value);
    };
    return { checkboxValue, checkboxChanged };
  },
});
