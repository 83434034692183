<template>
  <li class="topbar-menu-item" :class="{ checkbox: modelValue != undefined }">
    <template v-if="modelValue == undefined">
      <button v-if="!to" @click="$emit('click')">
        {{ name }}
      </button>
      <router-link v-if="to" :to="to">{{ name }}</router-link>
    </template>
    <template v-if="modelValue != undefined">
      <input
        :id="name"
        type="checkbox"
        v-model="checkboxValue"
        @change="checkboxChanged"
      />
      <label :for="name">{{ name }}</label>
    </template>
  </li>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  emits: {
    click: null,
    input: null,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    modelValue: {
      type: Boolean,
      default: undefined,
    },
    tooltip: String,
  },
  setup(props, { emit }) {
    const checkboxValue = ref(props.modelValue || false);
    const checkboxChanged = () => {
      emit("input", checkboxValue.value);
    };
    return { checkboxValue, checkboxChanged };
  },
});
</script>

<style lang="scss">
.topbar-menu-item {
  display: flex;
  align-items: center;

  @include min-md() {
    padding: 2px;

    &:hover {
      outline: 2px solid var(--white-500);
    }
  }

  &:not(:first-child) {
    border-top: 1px solid var(--white-500);
  }

  &.seperator {
    margin-bottom: 10px;
    border-bottom: 1px solid var(--white-500);
  }

  &.checkbox {
    @include checkbox($positionLeft: false, $label-padding: 12px 16px);
  }

  &.sub-item {
    padding-left: 16px;
    border-top: none;

    &::before {
      content: '-';
    }
  }

  label {
    flex: 1 1 auto;
  }

  button,
  a {
    @include button(secondary);
    align-items: flex-start;
    padding: 13.5px 16px !important;
    border: none !important;
    outline: none !important;
    width: 100%;
    backdrop-filter: none;
  }
}
</style>