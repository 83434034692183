<template>
  <template v-if="song">
    <Topbar>
      <TopbarItem v-if="isAdmin" name="Toevoegen aan" />
      <TopbarItem v-if="isAdmin" name="Wijzigen" :to="{ name: 'SongEdit' }" />
      <TopbarItem v-if="isAdmin" name="Verwijderen" @click="deleteSong" />
      <TopbarItem name="Printen" @click="printSong" class="seperator" />

      <TopbarItem
        name="Passend aan scherm"
        v-model="settings.responsive"
        @input="settings.responsive = $event"
      />
      <TopbarItem
        name="Akkoorden"
        v-model="settings.chords"
        @input="settings.chords = $event"
      />
      <TopbarItem
        v-if="settings.chords"
        name="Number System"
        v-model="settings.numberSystem"
        @input="settings.numberSystem = $event"
        class="sub-item"
      />
      <TopbarItem
        v-if="settings.chords"
        name="Toonhoogte"
        v-model="settings.transpose"
        @input="settings.transpose = $event"
        class="sub-item"
      />
      <TopbarItem
        v-if="settings.chords"
        name="Capo"
        v-model="settings.capo"
        @input="settings.capo = $event"
        class="sub-item"
      />
      <TopbarItem
        name="Suggesties"
        v-model="settings.suggestions"
        @input="settings.suggestions = $event"
      />
      <TopbarItem
        name="Metronoom"
        v-model="settings.metronome"
        @input="settings.metronome = $event"
      />
      <TopbarItem
        name="Backtrack"
        v-model="settings.backtrack"
        @input="settings.backtrack = $event"
      />
      <TopbarItem
        name="Nummer navigatie"
        v-model="settings.songNavigation"
        @input="settings.songNavigation = $event"
      />
    </Topbar>

    <Backtrack v-if="loggedIn" :song="song" />

    <section
      class="song-header"
      :class="{
        'crop-width': (settings.capo || settings.transpose) && settings.chords,
      }"
    >
      <h1>{{ song.title }}</h1>
      <small>{{ song.artist }}</small>
      <small v-if="loggedIn"
        >Ritme: {{ song.timeSignature || "?" }} -
        {{ song.bpm || "?" }}bpm</small
      >
      <small v-if="loggedIn"
        >Toonhoogte: {{ song.key + " (origineel)" || "?" }}</small
      >
    </section>
    <SongContent :song="song" />
    <SongSuggestions v-if="loggedIn" :song="song" />
    <Transposer v-if="loggedIn" :song="song" />
    <SongNavigation :song="song" />
  </template>
</template>

<script lang="ts">
import { defineComponent, computed, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useAppState } from "@/hooks/use-app-state";
import { useAuth } from "@/hooks/use-auth";
import { useUser } from "@/hooks/use-user";
import { useSongs } from "@/hooks/use-songs";
import { useSongParts } from "@/hooks/use-song-parts";
import Topbar from "@/components/Topbar.vue";
import TopbarItem from "@/components/TopbarItem.vue";
import SongContent from "@/components/song/SongContent.vue";
import SongSuggestions from "@/components/song/SongSuggestions.vue";
import SongNavigation from "@/components/song/SongNavigation.vue";
import Transposer from "@/components/Transposer.vue";
import Backtrack from "@/components/Backtrack.vue";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { settings, saveSettings } = useAppState();
    const { loggedIn } = useAuth();
    const { user } = useUser();
    const { getSong } = useSongs();
    const song = computed(() => getSong(route.params.id as string));

    const getSongParts = () => {
      if (song.value) {
        const { parts } = useSongParts(song.value);
        song.value.parts = parts;
      }
    };

    getSongParts();

    watch(
      () => song.value,
      () => {
        getSongParts();
      }
    );

    const deleteSong = async () => {
      var confirmed = window.confirm(
        "Weet je zeker dat je dit nummer wilt verwijderen?"
      );
      if (confirmed && song.value) {
        const { deleteSong } = useSongs();
        const title = song.value.title;
        const res = await deleteSong(song.value);
        if (res) {
          toast(`"${title}" is verwijderd.`);
          router.go(-1);
        } else {
          toast("Er is iets misgegaan, probeer later opnieuw.");
        }
      }
    };

    const printSong = () => {
      const responsive = settings.value.responsive;
      settings.value.responsive = true;
      nextTick(() => {
        window.print();
        settings.value.responsive = responsive;
      });
    };

    watch(settings.value, (curr, prev) => {
      saveSettings();
    });

    return {
      song,
      loggedIn,
      isAdmin: computed(() => loggedIn.value && user && user.isAdmin),
      settings,
      deleteSong,
      printSong,
    };
  },
  components: {
    Topbar,
    TopbarItem,
    SongContent,
    SongSuggestions,
    SongNavigation,
    Transposer,
    Backtrack,
  },
});
</script>

<style lang="scss">
.song-header {
  &.crop-width {
    max-width: calc(100% - 35px);
  }

  h1 {
    @include heading(h2);
  }

  small {
    display: block;
  }
}
</style>