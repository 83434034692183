import { reactive } from "vue";
import find from "lodash/find"
import throttle from "lodash/throttle";
import isEqual from "lodash/isEqual";
import { SongPart, SongPartLine, SongPartLineTypes } from '@/types/song';
import { useUtils, ScrollOptions } from "@/hooks/use-utils";

interface Anchor {
    part: SongPart,
    line: SongPartLine,
    current: boolean,
    repeated: number
}

export const useSongAnchors = (parts: Array<SongPart>, lineAmount: number = 2) => {
    const { smoothScroll } = useUtils();
    const textLines: Array<SongPartLine> = reactive([]);
    const anchors: Array<Anchor> = reactive([]);

    const createAnchors = () => {
        if (parts) {
            parts.forEach(part => {
                const lines = part.lines.filter(x => x.type == SongPartLineTypes.TEXT && x.content &&
                    x.content.replace(/\s/g, ""));

                lines.forEach((line, index) => {
                    textLines.push(line);

                    if (index % lineAmount == 0) {
                        anchors.push({ part: part, line: line, current: false, repeated: 1 });
                    }
                });
            });
        }
    }

    const getCurrentAnchor = (): Anchor | undefined => {
        return find(anchors, x => x.current == true);
    }

    const lineIsCurrentAnchor = (line: SongPartLine): boolean => {
        const anchor = getCurrentAnchor();
        return anchor ? anchor.line === line : false;
    }

    const lineIsAnchor = (line: SongPartLine): boolean => {
        return find(anchors, x => x.line === line) ? true : false;
    }

    const getActiveTextLines = (): Array<SongPartLine> => {
        const anchor = getCurrentAnchor();
        if (!anchor) return [];

        const activeTextLines = [];
        const currentAnchorIndex = textLines.indexOf(anchor.line);
        for (let i = currentAnchorIndex; i < textLines.length; i++) {
            if (lineIsCurrentAnchor(textLines[i]) || !lineIsAnchor(textLines[i])) {
                activeTextLines.push(textLines[i]);
            } else {
                break;
            }
        }
        return activeTextLines;
    }

    const lineIsActive = (line: SongPartLine): boolean => {
        const activeTextLines: Array<SongPartLine> = getActiveTextLines();
        return find(activeTextLines, x => x === line) ? true : false;
    }

    const setFirstAnchorCurrent = (): void => {
        if (anchors.length) {
            anchors[0].current = true;
        }
    }

    const nextAnchor = throttle((keyCode: number) => {
        if ((keyCode == 34 || keyCode == 33) && !getCurrentAnchor()) {
            setFirstAnchorCurrent();
            return;
        }

        if ((keyCode != 34 && keyCode != 33) || !anchors.length || !getCurrentAnchor()) {
            return;
        }

        const currentAnchor = getCurrentAnchor();
        if (currentAnchor) {
            const currentAnchorIndex = anchors.indexOf(currentAnchor);
            currentAnchor.current = false;

            if (keyCode == 34) {
                // PageDown
                let newAnchor = anchors[currentAnchorIndex + 1];
                if (!newAnchor) {
                    newAnchor = anchors[0];
                }

                // Check if the part needs to be repeated
                if (!isEqual(currentAnchor.part, newAnchor.part) && currentAnchor.part.repeated < currentAnchor.part.repeat) {
                    const firstAnchorOfPart = find(anchors, x => x.part == currentAnchor.part);
                    if (firstAnchorOfPart) {
                        newAnchor = firstAnchorOfPart;
                    }
                    currentAnchor.part.repeated++;
                }

                newAnchor.current = true;
            }

            if (keyCode == 33) {
                // PageUp
                let newAnchor = anchors[currentAnchorIndex - 1];
                if (!newAnchor) {
                    newAnchor = anchors[anchors.length - 1];
                }
                newAnchor.current = true;
            }

            setTimeout(function () {
                smoothScroll(document.querySelector("#anchor") as HTMLElement, { offset: -125 } as ScrollOptions);
            }, 1);
        }

    }, 325, { leading: true, trailing: false })

    createAnchors();

    return { anchors, lineIsCurrentAnchor, lineIsActive, nextAnchor };
}