
import find from "lodash/find";
import { computed, defineComponent, PropType, watch } from "vue";
import { Song } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useTranspose } from "@/hooks/use-transpose";
import { useAudio } from "@/hooks/use-audio";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { audioState, playing, playAudio, stopAudio, stopAllAudio } =
      useAudio();
    const { settings } = useAppState();
    const { getTranspose } = useTranspose();
    const transpose = computed(() => getTranspose(song));
    const progress = computed(() => audioState.progress);
    const audio = computed(() =>
      find(
        audioState.audios,
        (x) =>
          x.name == (settings.value.transpose ? transpose.value.key : song.key)
      )
    );

    const play = () => {
      playAudio(audio.value);
    };

    const stop = () => {
      if (audio.value.playing) {
        stopAllAudio(audio.value);
        stopAudio(audio.value, true);
      } else {
        stopAllAudio();
      }
    };

    watch([transpose.value, () => settings.value.transpose], () => {
      if (playing.value) {
        playAudio(audio.value);
      }
    });

    watch(
      () => settings.value.backtrack,
      () => {
        if (playing.value && !settings.value.backtrack) {
          stopAllAudio();
        }
      }
    );

    return { settings, transpose, playing, audio, play, stop, progress };
  },
});
