<template>
  <section
    class="song-content"
    :class="{
      responsive: settings.responsive,
      'crop-width': (settings.capo || settings.transpose) && settings.chords,
    }"
  >
    <div class="song-part" v-for="(part, i) in song.parts" :key="`part-${i}`">
      <p
        v-if="part.name"
        class="song-part-name"
        v-in-viewport="(e) => (part.inViewport = e)"
      >
        {{ part.name }}
      </p>

      <template v-for="(line, j) in part.lines">
        <p
          v-if="line.type == SongPartLineTypes.CHORD && settings.chords"
          class="chords"
          :key="`part-${i}-chord${j}`"
          v-html="chordify(transposed(line.content))"
        ></p>
        <p
          v-if="line.type == SongPartLineTypes.TEXT"
          class="text"
          :class="{
            'whitespace-normal': !settings.chords,
            active: lineIsActive(line),
          }"
          :id="lineIsCurrentAnchor(line) ? 'anchor' : null"
          :key="`part-${i}-text${j}`"
        >
          {{ line.content }}
        </p>
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  onMounted,
  computed,
  watch,
  nextTick,
  onUnmounted,
} from "vue";
import Transposer from "@/helpers/transpose/Transposer";
import { Song, SongPartLineTypes } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useTranspose } from "@/hooks/use-transpose";
import { useSongAnchors } from "@/hooks/use-song-anchors";
import { useUtils, ScrollOptions } from "@/hooks/use-utils";
import { throttle } from "lodash";

export default defineComponent({
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const { settings } = useAppState();
    const { lineIsCurrentAnchor, lineIsActive, nextAnchor } = useSongAnchors(
      song.parts
    );
    const { getTranspose } = useTranspose();
    const transpose = computed(() => getTranspose(song));
    const { smoothScroll } = useUtils();

    const chordify = (content: string): string => {
      return content.replace(/(\S+)/g, "<ch>$1</ch>");
    };

    const transposed = (content: string): string => {
      if (song.key) {
        if (settings.value.numberSystem) {
          return Transposer.transpose(content)
            .fromKey(song.key)
            .toNumberSystem();
        } else {
          let result = Transposer.transpose(content).fromKey(song.key);

          if (settings.value.transpose) {
            result = result.toKey(transpose.value.key);
          }

          if (settings.value.capo && transpose.value.capo > 0) {
            result = result.down(transpose.value.capo);
          }

          return result.toString();
        }
      }
      return content;
    };

    const setStickyStyleRight = () => {
      const chords = document.querySelectorAll(".chords");
      chords.forEach((line) => {
        let totalWidth = 0;
        for (let index = line.children.length - 1; index >= 0; index--) {
          const chord = line.children[index] as HTMLElement;
          const nextChord = line.children[index + 1] as HTMLElement;
          const chordChild = chord.children[0] as HTMLElement;

          if (chordChild && chordChild.textContent) {
            const characterWidth = window.innerWidth < 768 ? 9 : 9.61;
            chordChild.style.width =
              chordChild.textContent.length * characterWidth + "px"; // because <sub> has a smaller font, we want it the same width;
          }

          totalWidth += nextChord ? nextChord.offsetWidth + 6 : 0; // 6 for a little gap
          chord.style.right = totalWidth + "px";
        }
      });
    };

    const scrollVertical = throttle((offset: number = 0) => {
      smoothScroll(null, { offset } as ScrollOptions);
    }, 325, {
      leading: true,
      trailing: false,
    });

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode == 34 || event.keyCode == 33) {
        event.preventDefault();
        nextAnchor(event.keyCode);
      }

      if (event.keyCode == 38) {
        event.preventDefault();
        scrollVertical(-window.innerHeight * 0.4);
      }

      if (event.keyCode == 40) {
        event.preventDefault();
        scrollVertical(window.innerHeight * 0.4);
      }
    };

    watch([settings.value, transpose.value], () => {
      nextTick(() => setStickyStyleRight());
    });

    onMounted(() => {
      setStickyStyleRight();
      document.onkeydown = onKeyDown;
    });
    onUnmounted(() => (document.onkeydown = null));

    return {
      settings,
      SongPartLineTypes,
      chordify,
      transposed,
      lineIsCurrentAnchor,
      lineIsActive,
    };
  },
});
</script>

<style lang="scss">
.song-content {
  margin-top: 30px;
  overflow-x: auto;
  width: 100%;
  margin-left: -#{$grid-gutter};
  padding-left: $grid-gutter;

  &.responsive {
    overflow-x: hidden;
  }

  &.crop-width {
    max-width: calc(100% - 35px);
  }
}

.song-part {
  margin-bottom: 25px;
  padding-bottom: 25px;
  break-inside: avoid;
  position: relative;

  &.is--navigated::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 25px;
    left: -15px;
    width: 5px;
    background-color: var(--white-500);
    animation: fadeOut 1s ease-in-out 1s;
  }

  p {
    white-space: pre;
    margin-bottom: 0;

    &.song-part-name,
    &.chords {
      @include font-weight-bold();
    }

    &.active {
      position: relative;
      font-style: italic;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -15px;
        width: 5px;
        background-color: var(--white-500);
      }
    }

    .responsive & {
      &.text {
        white-space: pre-wrap;
      }

      &.chords ch {
        right: 0;
        position: sticky;
      }
    }

    &.whitespace-normal {
      white-space: normal !important;
    }

    sub {
      display: inline-block;
      min-width: 11px;
    }
  }
}
</style>